import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-207ecc36"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "global-body"
};
const _hoisted_2 = {
  class: "global-table"
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_6 = {
  key: 0
};
const _hoisted_7 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_8 = {
  key: 0
};
import { ref, reactive, computed, onMounted } from 'vue';
import { getGoodsList, delGoods, getActivities } from '@/api/goods'; // delGoods
// import { exportFile } from '@/api/order'
// import { Edit } from '@element-plus/icons-vue'

import { Refresh, Plus } from '@element-plus/icons-vue';
import dayjs from 'dayjs';
import ModalInfo from './modal-info.vue'; //

import ModalSortsSetting from './modal-sorts-setting.vue';
import { filterLabel } from '@/utils/common'; // AllDict,

import { ElMessage } from 'element-plus';
export default {
  __name: 'index',

  setup(__props) {
    const modalRef = ref();
    const modalSortsSetting = ref(); // 新建/编辑

    const handleAddOrEdit = record => {
      modalRef.value.showModal(record);
    }; // 编辑型号


    const handleSortsSetting = record => {
      modalSortsSetting.value.showModal(record);
    }; // 商品分类


    const shopType = [{
      value: '滤材',
      label: '滤材'
    }, {
      value: '器材',
      label: '器材'
    }, {
      value: '水剂',
      label: '水剂'
    }, {
      value: '饲料',
      label: '饲料'
    }]; // 上架状态

    const onlineStatelist = [{
      value: 1,
      label: '已上架'
    }, {
      value: 2,
      label: '已下架'
    }, {
      value: 3,
      label: '待上架'
    }];
    const tableData = ref([]);
    const loading = ref(false);

    const generateFrom = () => {
      return {
        type: '',
        subject: '',
        onlineState: ''
      };
    };

    const formModel = ref(generateFrom());
    const pagination = reactive({
      page_num: 1,
      page_size: 10,
      total: 0
    });
    const sendParams = computed(() => {
      return { ...pagination,
        ...formModel.value
      };
    }); // 获取表格数据

    const getTableData = async () => {
      loading.value = true;

      try {
        const resData = await getGoodsList(sendParams.value);

        if (resData.status === 'success') {
          tableData.value = resData.data;
          Object.assign(pagination, resData.paging);
        }

        loading.value = false;
      } catch (err) {
        console.log(err);
        loading.value = false;
      }
    }; // 初始化


    const init = async () => {
      await getTableData();
    };

    onMounted(() => {
      console.log('onMounted');
      init();
    }); // 页码变动

    const handleCurrentChange = async page => {
      pagination.page_num = page;
      await getTableData();
    };

    const onSubmit = () => {
      console.log('submit!');
      getTableData();
    }; // 重置


    const restSubmit = e => {
      if (e === '编辑') {
        getTableData();
      } else {
        formModel.value = generateFrom();
        Object.assign(pagination, {
          page_num: 1,
          page_size: 10,
          total: 0
        });
        getTableData();
      }
    }; // 时间格式化


    const dateFormat = date => {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    }; // 删除


    const handleDel = async record => {
      console.log(record); // 将商品所有的图片组合成一个数组

      const imgArr = [];
      const adPhotoUrlsArr = record.adPhotoUrls ? record.adPhotoUrls.split(',') : [];
      adPhotoUrlsArr.map(item => {
        imgArr.push(item);
      });
      const coverPhotoUrl = record.coverPhotoUrl ? record.coverPhotoUrl.split(',') : [];
      coverPhotoUrl.map(item => {
        imgArr.push(item);
      });
      const detailsPhotoUrls = record.detailsPhotoUrls ? record.detailsPhotoUrls.split(',') : [];
      detailsPhotoUrls.map(item => {
        imgArr.push(item);
      });
      console.log(imgArr);
      const newimgarr = [];
      imgArr.map(item => {
        console.log(item.split('=')[1]);
        newimgarr.push(item.split('=')[1]);
      });
      console.log(newimgarr); // 当前是连接数组 需要名称组成的数组

      delGoods({
        goodsId: record.goodsId,
        images: newimgarr
      }).then(res => {
        if (res.status === 'success') {
          ElMessage.success('删除成功');
          getTableData();
        }
      });
    }; // 获取活动信息


    const activitiesArr = ref([]);

    const getActivitiesFn = async type => {
      try {
        const resData = await getActivities({});

        if (resData.status === 'success') {
          activitiesArr.value = resData.data;
        }
      } catch (err) {
        console.log(err);
      }
    };

    const formatActivitiesStr = id => {
      const info = activitiesArr.value.find(item => {
        return item.activityId === id;
      });

      if (info) {
        return `${info.title}-${info.content}`;
      }

      return '-';
    }; // 获取活动信息


    getActivitiesFn(); // const exportExcel = () => {
    //   console.log('exportExcel')
    //   // 调用下载接口返回文件路径
    //   getGoodsList({
    //     ...formModel.value,
    //     exportOrdersExcel: true
    //   }).then((res) => {
    //     if (res.status === 200) {
    //       // download(res.data)
    //       exportFile({
    //         fileName: res.data
    //       })
    //     }
    //   })
    // }

    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_a_button = _resolveComponent("a-button");

      const _component_a_space = _resolveComponent("a-space");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_a_table_column = _resolveComponent("a-table-column");

      const _component_Goods = _resolveComponent("Goods");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_image = _resolveComponent("el-image");

      const _component_a_tag = _resolveComponent("a-tag");

      const _component_icon_edit = _resolveComponent("icon-edit");

      const _component_Money = _resolveComponent("Money");

      const _component_Coin = _resolveComponent("Coin");

      const _component_Guide = _resolveComponent("Guide");

      const _component_el_popconfirm = _resolveComponent("el-popconfirm");

      const _component_a_table = _resolveComponent("a-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _directive_loading = _resolveDirective("loading");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
        class: "global-search"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form, {
            inline: true,
            model: formModel.value,
            class: "demo-form-inline"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "商品分类"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: formModel.value.type,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.value.type = $event),
                placeholder: "请选择商品分类",
                size: "large",
                clearable: "",
                style: {
                  "width": "240px"
                }
              }, {
                default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(shopType, item => {
                  return _createVNode(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 64))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "商品标题"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: formModel.value.subject,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.value.subject = $event),
                placeholder: "请输入",
                style: {
                  "width": "200px"
                },
                clearable: ""
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "上架状态"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: formModel.value.onlineState,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.value.onlineState = $event),
                placeholder: "请选择上架状态",
                size: "large",
                style: {
                  "width": "240px"
                },
                clearable: ""
              }, {
                default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(onlineStatelist, item => {
                  return _createVNode(_component_el_option, {
                    key: item.value,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 64))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [_createVNode(_component_a_space, null, {
                default: _withCtx(() => [_createVNode(_component_a_button, {
                  type: "primary",
                  onClick: onSubmit
                }, {
                  default: _withCtx(() => [_createTextVNode("搜索")]),
                  _: 1
                }), _createVNode(_component_a_button, {
                  onClick: restSubmit
                }, {
                  default: _withCtx(() => [_createTextVNode("重置")]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        })]),
        _: 1
      }), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
        style: {
          "margin-top": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 24
        }, {
          default: _withCtx(() => [_createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "新建",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Plus),
              circle: "",
              onClick: _cache[3] || (_cache[3] = $event => handleAddOrEdit({}))
            }, null, 8, ["icon"])]),
            _: 1
          }), _createVNode(_component_el_tooltip, {
            class: "box-item",
            effect: "dark",
            content: "刷新",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: _unref(Refresh),
              circle: "",
              onClick: getTableData
            }, null, 8, ["icon"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_divider), _withDirectives((_openBlock(), _createBlock(_component_a_table, {
        data: tableData.value,
        style: {
          "width": "100%"
        },
        pagination: false,
        "header-cell-class": "a-table-header",
        "empty-text": "暂无订单数据",
        fit: true
      }, {
        columns: _withCtx(() => [_createVNode(_component_a_table_column, {
          "data-index": "goodsId",
          title: "商品ID",
          align: "center",
          width: "120",
          fixed: "left"
        }), _createVNode(_component_a_table_column, {
          "data-index": "subject",
          width: "220",
          fixed: "left",
          title: "商品标题"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Goods)]),
            _: 1
          }), _createTextVNode(" " + _toDisplayString(record.subject), 1)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "coverPhotoUrl",
          title: "封面图",
          width: "120",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createElementVNode("div", _hoisted_3, [!record.coverPhotoUrl ? (_openBlock(), _createElementBlock("span", _hoisted_4, "-")) : (_openBlock(), _createBlock(_component_el_image, {
            key: 1,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: record.coverPhotoUrl,
            "preview-src-list": [record.coverPhotoUrl],
            fit: "cover",
            "preview-teleported": true
          }, null, 8, ["src", "preview-src-list"]))])]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "adPhotoUrls",
          title: "广告图",
          width: "120",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createElementVNode("div", _hoisted_5, [!record.adPhotoUrls ? (_openBlock(), _createElementBlock("span", _hoisted_6, "-")) : (_openBlock(), _createBlock(_component_el_image, {
            key: 1,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: record.adPhotoUrls.split(',')[0],
            "preview-src-list": record.adPhotoUrls ? record.adPhotoUrls.split(',') : [],
            fit: "cover",
            "preview-teleported": true
          }, null, 8, ["src", "preview-src-list"]))])]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "detailsPhotoUrls",
          title: "详情图",
          width: "120",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createElementVNode("div", _hoisted_7, [!record.detailsPhotoUrls ? (_openBlock(), _createElementBlock("span", _hoisted_8, "-")) : (_openBlock(), _createBlock(_component_el_image, {
            key: 1,
            style: {
              "width": "100px",
              "height": "100px"
            },
            src: record.detailsPhotoUrls.split(',')[0],
            "preview-src-list": record.detailsPhotoUrls ? record.detailsPhotoUrls.split(',') : [],
            fit: "cover",
            "preview-teleported": true
          }, null, 8, ["src", "preview-src-list"]))])]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "specification",
          title: "商品介绍",
          width: "180"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_a_tag, null, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(record.specification), 1)]),
            _: 2
          }, 1024)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "specification",
          title: "商品型号",
          width: "180"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_a_button, {
            size: "small",
            type: "primary",
            status: "success",
            onClick: $event => handleSortsSetting(record)
          }, {
            icon: _withCtx(() => [_createVNode(_component_icon_edit)]),
            default: _withCtx(() => [_createTextVNode("编辑型号")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "onlineState",
          title: "上架状态",
          width: "120",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [record.onlineState == '1' ? (_openBlock(), _createBlock(_component_a_tag, {
            key: 0,
            color: "#00b42a",
            type: "info"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(filterLabel)(record.onlineState, 'onlineStateDict')), 1)]),
            _: 2
          }, 1024)) : record.onlineState == '2' ? (_openBlock(), _createBlock(_component_a_tag, {
            key: 1,
            color: "#f53f3f",
            type: "success"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(filterLabel)(record.onlineState, 'onlineStateDict')), 1)]),
            _: 2
          }, 1024)) : record.onlineState == '3' ? (_openBlock(), _createBlock(_component_a_tag, {
            key: 2,
            color: "#ffb400",
            type: "danger"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(filterLabel)(record.onlineState, 'onlineStateDict')), 1)]),
            _: 2
          }, 1024)) : _createCommentVNode("", true)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "unitPrice",
          title: "单价(元)",
          width: "120",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Money)]),
            _: 1
          }), _createTextVNode(" " + _toDisplayString(record.unitPrice), 1)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "sales",
          title: "销售量",
          width: "140",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Coin)]),
            _: 1
          }), _createTextVNode(" " + _toDisplayString(record.sales || '-'), 1)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "stocks",
          title: "库存量",
          width: "140",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Guide)]),
            _: 1
          }), _createTextVNode(" " + _toDisplayString(record.stocks || '-'), 1)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "activityId",
          title: "商品活动",
          width: "200",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Guide)]),
            _: 1
          }), _createTextVNode(" " + _toDisplayString(formatActivitiesStr(record.activityId)), 1)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "stocks",
          title: "平台活动",
          width: "200",
          align: "center"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_el_icon, null, {
            default: _withCtx(() => [_createVNode(_component_Guide)]),
            _: 1
          }), _createTextVNode(" " + _toDisplayString(formatActivitiesStr(record.mallActivityId)), 1)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "createdAt",
          title: "创建时间",
          width: "140"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_a_tag, {
            type: "info"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(record.createdAt ? dateFormat(record.createdAt) : '无'), 1)]),
            _: 2
          }, 1024)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          "data-index": "updatedAt",
          title: "更新时间",
          width: "140"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_a_tag, {
            type: "info"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(record.updatedAt ? dateFormat(record.updatedAt) : '无'), 1)]),
            _: 2
          }, 1024)]),
          _: 1
        }), _createVNode(_component_a_table_column, {
          title: "操作",
          align: "center",
          width: "160",
          fixed: "right"
        }, {
          cell: _withCtx(({
            record
          }) => [_createVNode(_component_a_space, null, {
            default: _withCtx(() => [_createVNode(_component_a_button, {
              size: "small",
              type: "primary",
              onClick: $event => handleAddOrEdit(record)
            }, {
              default: _withCtx(() => [_createTextVNode("编辑")]),
              _: 2
            }, 1032, ["onClick"]), _createVNode(_component_el_popconfirm, {
              title: "确定要删除这条数据吗?",
              onConfirm: $event => handleDel(record)
            }, {
              reference: _withCtx(() => [_createVNode(_component_a_button, {
                size: "small",
                status: "danger"
              }, {
                default: _withCtx(() => [_createTextVNode("删除")]),
                _: 1
              })]),
              _: 2
            }, 1032, ["onConfirm"])]),
            _: 2
          }, 1024)]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, loading.value]])]), _createVNode(_component_el_row, {
        justify: "end",
        class: "mt-20"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_pagination, {
          onCurrentChange: handleCurrentChange,
          "page-size": pagination.page_size,
          modelValue: pagination.page_num,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => pagination.page_num = $event),
          background: "",
          layout: "total,prev, pager, next",
          total: pagination.total
        }, null, 8, ["page-size", "modelValue", "total"])]),
        _: 1
      }), _createVNode(ModalInfo, {
        ref_key: "modalRef",
        ref: modalRef,
        onRefresh: restSubmit
      }, null, 512), _createVNode(ModalSortsSetting, {
        ref_key: "modalSortsSetting",
        ref: modalSortsSetting
      }, null, 512)]);
    };
  }

};